<template>
  <section id="contato" class="contact-section">
    <h1 class="section-title">Contato</h1>
    <p class="contact-description">
      Fale conosco clicando no botão abaixo ou encaminhe um e-mail para 
      <a href="mailto:contato@loomin.net" class="email-span">contato@loomin.net</a>
    </p>
    <a href="#" @click.prevent="openModal" class="button contact-button">Entre em contato</a>
    <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
    
    <div v-if="isModalOpen" class="modal-overlay">
      <div class="modal-wrapper">
        <label @click="closeModal" class="close"></label>
        <h2 id="modal-title">Mande uma mensagem</h2>
        <form @submit.prevent="sendEmail" ref="contactForm" class="message-form">
          <div class="input-box" :class="{ focus: isNameFocused || name }">
            <label for="name" class="input-label">Nome</label>
            <input
              type="text"
              id="name"
              name="name" 
              v-model="name"
              @focus="isNameFocused = true"
              @blur="isNameFocused = !!name"
              class="input-text"
              required
            />
            <hr class="underline">
            <hr class="underline-focus">
          </div>
          <div class="input-box" :class="{ focus: isEmailFocused || email }">
            <label for="email" class="input-label">E-mail</label>
            <input
              type="email"
              id="email"
              name="email" 
              v-model="email"
              @focus="isEmailFocused = true"
              @blur="isEmailFocused = !!email"
              class="input-text"
              required
            />
            <hr class="underline">
            <hr class="underline-focus">
          </div>
          <div class="input-box textarea" :class="{ focus: isMessageFocused || message }">
            <label for="message" class="input-label">Mensagem</label>
            <textarea
              id="message"
              name="message" 
              v-model="message"
              @focus="isMessageFocused = true"
              @blur="isMessageFocused = !!message"
              class="input-text"
              required
              rows="4"
              maxlength="160"
            ></textarea>
            <hr class="underline">
            <hr class="underline-focus">
          </div>
          <div class="input-button">
            <button type="submit" class="button">Enviar</button>
          </div>
        </form>
      </div>
    </div>

    <div class="scroll-up" @click="scrollToHome">
      &#8250;
    </div>
  </section>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  data() {
    return {
      isModalOpen: false,
      name: '',
      email: '',
      message: '',
      isNameFocused: false,
      isEmailFocused: false,
      isMessageFocused: false,
      successMessage: '',
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    sendEmail() {
      const serviceID = "service_2ln5rzm";
      const templateID = "template_ms3gkwb";
      const userID = "ulcgPiCOE8Hpr53fs";

      emailjs
        .sendForm(serviceID, templateID, this.$refs.contactForm, userID)
        .then(
          () => {
            this.successMessage = "Mensagem enviada com sucesso!";
            this.closeModal();
            this.name = '';
            this.email = '';
            this.message = '';
            setTimeout(() => { this.successMessage = ''; }, 5000);
          },
          (error) => {
            alert("Erro ao enviar a mensagem. Tente novamente.");
            console.error("Error:", error);
          }
        );
    },
    scrollToHome() {
      const servicesSection = document.querySelector("#home");
      servicesSection.scrollIntoView({ behavior: "smooth" });
    }
  },
};
</script>

<style scoped>
.contact-section {
  background-color: #333;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #fff;
  text-align: center;
}

.contact-description {
  color: #cdcdcd;
  margin: 1rem 0 2rem;
  text-align: center;
  max-width: 400px;
  font-size: 1.5rem;
}

.email-span {
  color: #89CFF0;
}

.success-message {
  color: #3ac28c;
  font-size: 1rem;
  margin-top: 10px;
}

.contact-button {
  margin: 1em;
  padding: 20px 46px;
  font-size: 1em;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.6s ease;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #000; 
}

.contact-button:hover {
  color: #ffffff;
  background-color: #000000;
  border: 1px solid #fff;
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-wrapper {
  background-color: #333;
  padding: 2em;
  max-width: 500px;
  width: 90%;
  position: relative;
  border-radius: 8px;
}
.modal-wrapper .close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-wrapper .close:before, .modal-wrapper .close:after {
  content: '';
  position: absolute;
  width: 2px;
  height: 20px;
  background-color: #89CFF0;
}
.modal-wrapper .close:before {
  transform: rotate(45deg);
}
.modal-wrapper .close:after {
  transform: rotate(-45deg);
}

.message-form .input-button {
  text-align: center;
  padding: 20px;
}
.message-form .input-button .button {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  transition: all 0.4s;
  padding: 10px 20px;
}
.message-form .input-button .button:hover {
  color: #89CFF0;
  border-color: #89CFF0;
}

.input-box {
  position: relative;
  margin: 1.5em 0;
}
.input-box .input-label {
  color: #666;
  position: absolute;
  top: 1em;
  left: 0;
  transform-origin: left;
  transition: all 0.3s;
  pointer-events: none;
}
.input-box.focus .input-label {
  color: #ffd700;
  transform: scale(0.75) translateY(-1.5em);
}
.input-box .input-text {
  width: 100%;
  padding: 1.5em 0;
  background: transparent;
  color: #fff;
  border: none;
  outline: none;
  font-size: 1em;
}
.input-box .underline {
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #666;
}
.input-box .underline-focus {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #89CFF0;
  transform: scaleX(0);
  transition: transform 0.4s;
}
.input-box.focus .underline-focus {
  transform: scaleX(1);
}
textarea.input-text {
  height: 150px;
  resize: none; 
}

.scroll-up {
  bottom: 20px;
  font-size: 2.5rem;
  color: #89CFF0;
  cursor: pointer;
  transition: color 0.3s ease;
  transform: rotate(-450deg);
  animation: bounce 1.5s infinite;
}

.scroll-up:hover {
  color: #fff;
}

input:-webkit-autofill, 
textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #333 inset;
  -webkit-text-fill-color: #fff;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #333 inset;
  -webkit-text-fill-color: #fff;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0) rotate(-450deg);
  }
  50% {
    transform: translateY(10px) rotate(-450deg);
  }
}
</style>
