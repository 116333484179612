<template>
  <div id="app">
    <NavBar />
    <HomeSection />
    <ServicesSection />
    <ContactSection />
    <FooterComponent />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import HomeSection from "./components/HomeSection.vue";
import ServicesSection from "./components/ServicesSection.vue";
import ContactSection from "./components/ContactSection.vue";
import FooterComponent from './components/FooterComponent.vue';

import "./assets/styles.css";

export default {
  name: 'App',
  components: {
    NavBar,
    HomeSection,
    ServicesSection,
    ContactSection,
    FooterComponent,
  },
};
</script>
