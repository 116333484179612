<template>
  <section id="servicos" class="services-section">
    <h1>Serviços</h1>
    <p class="services-description">
      Oferecemos desenvolvimento completo com tecnologias modernas, unindo linguagens de programação robustas a interfaces dinâmicas. Conte com a gente para criar soluções eficientes e inovadoras para as suas necessidades.
    </p>
    <div class="carousel-container logos">
      <div class="carousel-content logo_items">
        <div class="icon"><img src="@/assets/icons/mysql.svg" alt="MySQL" /></div>
        <div class="icon"><img src="@/assets/icons/php.png" alt="PHP" /></div>
        <div class="icon"><img src="@/assets/icons/javascript.svg" alt="JavaScript" /></div>
        <div class="icon"><img src="@/assets/icons/python.svg" alt="Python" /></div>
        <div class="icon"><img src="@/assets/icons/java.svg" alt="Java" /></div>
        <div class="icon"><img src="@/assets/icons/vuejs.svg" alt="Vue.js" /></div>
        <div class="icon"><img src="@/assets/icons/docker.svg" alt="Docker" /></div>
        <div class="icon"><img src="@/assets/icons/laravel.svg" alt="Laravel" /></div>

        <div class="icon"><img src="@/assets/icons/mysql.svg" alt="MySQL" /></div>
        <div class="icon"><img src="@/assets/icons/php.png" alt="PHP" /></div>
        <div class="icon"><img src="@/assets/icons/javascript.svg" alt="JavaScript" /></div>
        <div class="icon"><img src="@/assets/icons/python.svg" alt="Python" /></div>
        <div class="icon"><img src="@/assets/icons/java.svg" alt="Java" /></div>
        <div class="icon"><img src="@/assets/icons/vuejs.svg" alt="Vue.js" /></div>
        <div class="icon"><img src="@/assets/icons/docker.svg" alt="Docker" /></div>
        <div class="icon"><img src="@/assets/icons/laravel.svg" alt="Laravel" /></div>
      </div>
    </div>
    <button class="quote-button" @click="scrollToFooter">Faça um orçamento</button>
  </section>
</template>

<script>
export default {
  methods: {
    scrollToFooter() {
      const footer = document.querySelector("footer"); 
      footer.scrollIntoView({ behavior: "smooth" }); 
    }
  }
};
</script>

<style scoped>
.services-section {
  background-color: #f4f4f4;
  color: #333;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-size: 2.5rem;
  color: #333333;
  margin-bottom: 1rem;
}

.services-description {
  font-size: 1.5rem;
  max-width: 800px;
  margin-bottom: 2rem;
  color: #555555;
  line-height: 1.6;
}

.carousel-container {
  overflow: hidden;
  width: 100%;
  max-width: 1200px;
  position: relative;
  padding: 30px 0;
}

.carousel-container:before,
.carousel-container:after {
  position: absolute;
  top: 0;
  content: '';
  width: 250px;
  height: 100%;
  z-index: 2;
}

.carousel-container:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), #f4f4f4);
}

.carousel-container:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #f4f4f4);
}

.carousel-content {
  display: inline-flex;
  animation: slides 15s linear infinite;
}

.icon {
  display: inline-block;
  margin-right: 1.5rem;
}

.icon img {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

@keyframes slides {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.carousel-container:hover .carousel-content {
  animation-play-state: paused;
}

/* Estilo do botão de orçamento */
.quote-button {
  margin: 1em;
  padding: 20px 46px;
  font-size: 1em;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.6s ease;
  background-color: #000;
  color: #fff;
}

.quote-button:hover {
  color: rgb(0, 0, 0);
  background-color: #ffffff;
}

@media (max-width: 768px) {
  .carousel-container {
    width: 80%;
    padding: 0;
  }

  .carousel-content {
    display: inline-flex;
    animation: smooth-slides 20s linear infinite; /* Animação mais longa e contínua */
  }

  .icon img {
    width: 80px;
    height: 80px;
  }

  .quote-button {
    margin-top: 2em;
    padding: 16px 40px;
  }
  
}

/* Animação de transição contínua e suave */
@keyframes smooth-slides {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}



</style>
