<template>
    <footer class="footer">
      <p>&copy; Copyright 2024. Todos os direitos reservados.</p>
      <p>CNPJ: 34.208.688/0001-11</p>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterComponent',
  };
  </script>
  
  <style scoped>
  .footer {
    background-color: #f0f0f0;
    color: #333;
    text-align: center;
    padding: 1rem;
    font-size: 0.85rem;
    border-top: 1px solid #ffd700;
    font-family: Arial, sans-serif;
  }
  .footer p {
    margin: 0.2rem;
  }
  </style>
  