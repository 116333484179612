<template>
  <section id="home" class="home-section">
    <div class="content">
      <h1>
        <span class="animated-text">Loomin</span>
      </h1>
      <p :key="currentSlide" class="slide-text">{{ slides[currentSlide].text }}</p>
    </div>
    <div class="scroll-down" @click="scrollToServices">
      &#8250;
    </div>
    <div class="navigation-dots">
      <span
        v-for="(slide, index) in slides"
        :key="index"
        :class="{ active: currentSlide === index }"
        @click="changeSlide(index)"
      ></span>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      currentSlide: 0,
      slides: [
        { text: "Consultoria em Tecnologia da Informação" },
        { text: "Soluções Personalizadas para a sua Empresa" },
        { text: "Desenvolvimento Web e Suporte Técnico" }
      ]
    };
  },
  mounted() {
    this.startAutoSlide();
  },
  methods: {
    scrollToServices() {
      const servicesSection = document.querySelector("#servicos");
      servicesSection.scrollIntoView({ behavior: "smooth" });
    },
    changeSlide(index) {
      this.currentSlide = index;
    },
    startAutoSlide() {
      setInterval(() => {
        this.currentSlide = (this.currentSlide + 1) % this.slides.length;
      }, 5000);
    }
  }
};
</script>

<style scoped>
.home-section {
  background-color: #2f2f2f;
  color: #ffffff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.content h1 {
  font-size: 3rem;
  color: #89CFF0;
}

.animated-text {
  display: inline-block;
  animation: slideIn 1s ease forwards;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-text {
  font-size: 1.5rem;
  color: #ffffff;
  margin-top: 0.5rem;
  opacity: 0;
  animation: fadeSlide 1s ease forwards;
  transition: opacity 0.8s ease;
}

@keyframes fadeSlide {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.navigation-dots {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.navigation-dots span {
  width: 10px;
  height: 10px;
  background-color: #666;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.navigation-dots span.active {
  background-color: #89CFF0;
}

.scroll-down {
  position: absolute;
  bottom: 20px;
  font-size: 2.5rem;
  color: #ffd700;
  cursor: pointer;
  transition: color 0.3s ease;
  transform: rotate(90deg);
  animation: bounce 1.5s infinite;
}

.scroll-down:hover {
  color: #fff;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0) rotate(90deg);
  }
  50% {
    transform: translateY(10px) rotate(90deg);
  }
}
</style>
