<template>
  <nav>
    <div id="menuToggle" ref="menuToggle">
      <input type="checkbox" v-model="isMenuOpen" />

      <span></span>
      <span></span>
      <span></span>

      <ul id="menu">
        <li><a @click.prevent="scrollTo('home')">Home</a></li>
        <li><a @click.prevent="scrollTo('servicos')">Serviços</a></li>
        <li><a @click.prevent="scrollTo('contato')">Contato</a></li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    scrollTo(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    handleClickOutside(event) {
      if (this.isMenuOpen && !this.$refs.menuToggle.contains(event.target)) {
        this.closeMenu();
      }
    },
  },
  watch: {
    isMenuOpen(newVal) {
      const checkbox = this.$el.querySelector("input[type='checkbox']");
      if (!newVal) {
        checkbox.checked = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
#menuToggle {
  display: block;
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
  color: #232323;
  transition: color 0.3s ease;
}

#menuToggle a:hover {
  color: #ffd700;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}

#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #ebca11;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 150px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 254, 254, 0.2); 
  backdrop-filter: blur(10px);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform: translateX(-100%);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
  z-index: 0;
  padding: 0;
}

#menu li {
  padding: 10px 0;
  font-size: 28px;
}

#menuToggle input:checked ~ ul {
  transform: translateX(0);
}

@media (max-width: 768px) {
  #menu {
    top: 0;
    left: 0;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0;
  }
  
  #menuToggle input:checked ~ ul {
    transform: translateY(0);
  }
}

</style>
